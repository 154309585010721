
/**
 * 공통 사용을 위한 스토어
 *  - 공통 컴포넌트 제외 컴포넌트 간 종속 금지.
 */
const stateValue = {
  toast : null, //권한
  confirm : null,//사용자 정보
  alert : null//사용자 정보
}
export default {
    //하나의 store안에서 모듈화되어 사용될 수 있음을 명시
    namespaced : true,
    // 실제로 취급해야하는 각각의 data(==data)
    state: () => (stateValue),
    getters: {
      getToast(state) {
        return state.toast;
      },

      getConfirm(state) {
        return state.confirm;
      },
      getAlert(state) {
        return state.alert;
      }
    },
    

    //✅mutations를 통에서만 데이터를 변경할 수 있습니다.
    mutations: {
      showToast(state, param) {
        state.toast.showToast(param.type, param.msg);
      },

      showAlert(state, param) {
        state.alert.showAlert(param.type, param.title, param.msg, param.callBack);
      },

      showConfirm(state, param) {
        state.confirm.showConfirm(param.type, param.title, param.msg, param.callBack, param.checked, param.isShowTextarea || false);
      },

      initRef(state, param) {
        state[param.key] = param.ref;
      }
    }, 

    //actions의 함수들은 비동기로만 동작
    //✅async, await를 붙이지 않아도 비동기로만동작한다.
    actions: {


      showToast({ commit }, param) {

        commit('showToast', param);
      },
      
      showAlert({commit}, param) {
        commit('showAlert', param);
      },

      showConfirm({commit}, param) {
        commit('showConfirm', param);
      }
    }
    
  }