import GmxGlobal from "@/js/global";
import requsetApI from "@/js/request/requsetApI";
import { getLocalItem } from "@/js/utils/StorageUtil";
import store from '@/js/store';

const CONTEXT = `/${GmxGlobal.SYSTEM_NAME}/`;
//********DB mt_auth_list 에 대한 mgr_seq와 접근 URL 매핑
//DB에 넣자...까불지말거
const PAGE_CODE = {
    "main": 2,
    "main/sales": 4,
    "main/project": 5,
    "main/orders": 6,
    "main/outs": 7,
    "system": 3,
    "system/user": 8,
    "system/auth": 9,
    "system/history": 10,
    "system/company": 11,
    "system/client": 12,
    "system/solution": 13,
    "salesAdd": 14,
    "salesDel": 16,
    "projectAdd": 17,
    "projectDel": 19,
    "ordersAdd": 20,
    "ordersDel": 22,
    "outsAdd": 23,
    "outsDel": 25,
    "userAdd": 26,
    "userDel": 27,
    "companyAdd": 28,
    "companyDel": 29,
    "clientAdd": 30,
    "clientDel": 31,
    "solutionAdd": 32,
    "solutionDel": 33,
    "targetsalesAdd": 34,
    "system/targetsales": 35,
    "report": 36,
    "report/cont": 37,
    "report/sales": 38,
    "system/file": 39,
    "system/mail": 40,
    "main/biz": 41,
    "report/board": 42,
    "main/mypage": 43
};
// 1;"01";"1000";"대시보드"
// 2;"01";"2000";"계약현황 관리"
// 3;"01";"3000";"시스템 관리"
// 4;"02";"2100";"영업현황 관리"
// 5;"02";"2200";"프로젝트 관리"
// 6;"02";"2300";"수주계약 관리"
// 7;"02";"2400";"외주계약 관리"
// 8;"02";"3100";"사용자 관리"
// 9;"02";"3200";"권한 관리"
// 10;"02";"3300";"사용자활동기록 관리"
// 11;"02";"3400";"거래처 관리"
// 12;"02";"3500";"발주처 관리"
// 13;"02";"3500";"솔루션 관리"
// 14;"03";"2101";"영업현황 등록/수정"
// 16;"03";"2103";"영업현황 삭제"
// 17;"03";"2201";"프로젝트 등록/수정"
// 19;"03";"2203";"프로젝트 삭제"
// 20;"03";"2301";"수주계약 등록/수정"
// 22;"03";"2303";"수주계약 삭제"
// 23;"03";"2401";"외주계약 등록/수정"
// 25;"03";"2403";"외주계약 삭제"
// 26;"03";"2501";"사용자관리 등록/수정"
// 27;"03";"2503";"사용자관리 삭제"
// 28;"03";"2601";"거래처관리 등록/수정"
// 29;"03";"2603";"거래처관리 삭제"
// 30;"03";"2701";"발주처관리 등록/수정"
// 31;"03";"2703";"발주처관리 삭제"
// 32;"03";"2801";"솔루션관리 등록/수정"
// 33;"03";"2803";"솔루션관리 삭제"


const chkUrl = async (auth, url) => {
    let isChk = false;
    if (!url) return;
    for (const i in auth) {
        const item = auth[i];
        const authSeq = item.authMgrSeq;
        url = url.replace(CONTEXT, '');
        if (PAGE_CODE[url] == authSeq) {
            isChk = true;
            continue;
        }
    }

    return isChk;
}

const getAuthData = async () => {
    const ugrpCd = await requsetApI.findSyncUserInfo({ userId: getLocalItem('eUserId') }).then((res1) => {

        if (res1.data.results.length > 0) {
            store.dispatch('systemInfo/setUserInfo', res1.data.results[0]);

            return res1.data.results[0].authGrpSeq;
        }
    });
    
    const auth = await requsetApI.selectSyncAuthJoin({ grpMgrSeq: ugrpCd }).then((res) => {

        store.dispatch('systemInfo/setAuthList', res.data.results);

        return res.data.results;
    });

    return auth;
}

const chkUrlApi = async (auth, url) => {
    if (!auth) {
       
        const auth = await getAuthData();

        return chkUrl(auth, url);
    } else {
        return chkUrl(auth, url);
    }

}


export default {
    /**
     * 라우터에서 사용되는 URL 권한 체크
     *
     * @param {*} auth 
     */
    chkUrl: async (auth, url) => {
        const req = await chkUrlApi(auth,url);
        return req
    },

    /**
     * 비동기 문제로 인해.. 위에 함수가 안먹어서 생성함.
     *  - 동일한 처리...
     * @param {d} auth 
     * @param {*} url 
     * @returns 
     */
    chkUrlOrg: (url) => {
        const auth = store.getters['systemInfo/getAuthList'];
    
        if (auth) {
            
            let isChk = false;
            for (let i = 0; i < auth.length; i++) {
                const item = auth[i];
                const authSeq = item.authMgrSeq;
                url = url.replace(CONTEXT, '');
                
                if (PAGE_CODE[url] == authSeq) {

                    isChk = true;
                    continue;
                }
            }

            return isChk;
        }

    },

    /**
         * 비동기 문제로 인해.. 위에 함수가 안먹어서 생성함.
         *  - 동일한 처리...
         * @param {d} auth 
         * @param {*} url 
         * @returns 
         */
    chkUrlAsyncOrg: async (url) => {
        let auth = store.getters['systemInfo/getAuthList'];

        if ( !auth ) {
            
            auth = await getAuthData();
        }

        if (auth) {
            let isChk = false;
            for (let i = 0; i < auth.length; i++) {
                const item = auth[i];
                const authSeq = item.authMgrSeq;
                url = url.replace(CONTEXT, '');
                
                if (PAGE_CODE[url] == authSeq) {

                    isChk = true;
                    continue;
                }
            }

            return isChk;
        }

    },
    // /**
    //  * 로그인 시 첫 권한 없는 페이지 접근하는 경우...
    //  * 
    //  * @returns 
    //  */
    initFirstPageAsync : async (next, router) => {
        
        let auth = store.getters['systemInfo/getAuthList'];
        if ( !auth ) {
            
            auth = await getAuthData();
            console.log(auth);
        }

        let isNext = false;
        if (auth) {
            for (let k in PAGE_CODE) {
                const authKey = PAGE_CODE[k];
                if (k.indexOf('/') == -1) continue;
                
                for (let i in auth) {
                    var currentAuth = auth[i].authMgrSeq;
                    if (authKey == currentAuth) {
                       if ( next ) next('/' + GmxGlobal.SYSTEM_NAME + '/' + k);
                       if ( router) router.push('/' + GmxGlobal.SYSTEM_NAME + '/' + k);

                       isNext = true;
                    }
                }
            }
        }

        if (!isNext && next) {
            next(GmxGlobal.LOGIN_PAGE);
        } 
    },

        // /**
    //  * 로그인 시 첫 권한 없는 페이지 접근하는 경우...
    //  * 
    //  * @returns 
    //  */
    initFirstPage: (next, router) => {
        
        let auth = store.getters['systemInfo/getAuthList'];
        // if ( !auth ) {
            
        //     auth = await getAuthData();
        //     console.log(auth);
        // }

        let isNext = false;
        if (auth) {
            for (let k in PAGE_CODE) {
                const authKey = PAGE_CODE[k];
                if (k.indexOf('/') == -1) continue;
                
                for (let i in auth) {
                    var currentAuth = auth[i].authMgrSeq;
                    if (authKey == currentAuth) {
                       if ( next ) next('/' + GmxGlobal.SYSTEM_NAME + '/' + k);
                       if ( router) router.push('/' + GmxGlobal.SYSTEM_NAME + '/' + k);

                       isNext = true;
                    }
                }
            }
        }

        if (!isNext && next) {
            next(GmxGlobal.LOGIN_PAGE);
        } 
    },

};

