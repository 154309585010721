<template>
    <div class="gmx_confirm" v-if="isShow" >
        <div class="gmx_confirm_container flex flex_column flex_center_3">
                <div name="head" class="flex flex_column flex_center_3">
                    <template v-for="(item, index) in typeList" :key="index">
                        <template v-if="type === index.toString()">
                            <div class="confirm_type flex flex_center_3" :class="`type_${index}`"></div>
                        </template>
                    </template>
                    <h3 class="confirm_title">{{ title }}</h3>
                </div>
                <div class="body" name="body">
                    <div class="body" v-html="msg"></div>
                    <div v-if="isShowTextarea" class="textarea_wrapper">
                        <textarea v-model="textareaValue" placeholder="담당자가 확인할 수 있는 의견을 입력해 주세요"></textarea>
                    </div>
                    <div v-if="checked"  class="option">
                        <div>
                            <GmxCheckBox :label="checked.label" v-model="checked.value"></GmxCheckBox>
                        </div>
                    </div>                    
                </div>
                <div name="footer" class="button_container">
                    <GmxButton title="취소" cl="d" @click="_handleClickCancle"></GmxButton>
                    <GmxButton title="확인" cl="e" @click="handleClickConfirm"></GmxButton>
                </div>
        </div>
    </div>
</template>

<script setup>
import {  ref, defineExpose } from 'vue';
import GMXGlobal from '@/js/global';
import GmxButton from '../button/GmxButton.vue';
import GmxCheckBox from '../checkBox/GmxCheckBox.vue';

// MSG_STATE_ERROR : '0', //오류
// MSG_STATE_WRAN : '1', //경고
// MSG_STATE_INFO : '2', //정보
// MSG_STATE_OK : '3', //성공
const title = ref('');
const msg = ref('');
const type = ref(GMXGlobal.MSG_STATE_INFO);
const isShow = ref(false);
const isCancle = ref(false);
const callBack = ref(null);
const checked = ref(null);
// textarea 관련
const textareaValue = ref('');
const isShowTextarea = ref(false);

const showConfirm = (_type, _title, _msg, _callBack, _checked, _isShowTextarea, _textareaValue) => {
    type.value = _type;
    title.value = _title;
    msg.value = _msg;
    isShow.value = true;
    callBack.value = _callBack;
    isShowTextarea.value = _isShowTextarea;
    textareaValue.value = _textareaValue;
    checked.value = _checked;
};

const handleClickConfirm = () => {
    callBack.value(true, isCancle.value, isShowTextarea.value ? textareaValue.value : null); // textarea 사용 시 값 전달
    isShow.value = false;
}

const _handleClickCancle = () => {
    callBack.value(false);
    isShow.value = false;
}

defineExpose({
    showConfirm,
    handleClickConfirm,
    _handleClickCancle,
    callBack
})

//이미지를 위해 따로 구성했음.
const typeList = [
    {
        typeText : "오류"
    },
    {
        typeText : "경고"
    },
    {
        typeText : "정보"
    },
    {
        typeText : "성공"
    }
]

</script>

<style lang="scss" scoped>

.gmx_confirm {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);

    .gmx_confirm_container {
        width: 458px;
        padding: 28px 0;
        background-color: $gmx-theme-color;
        border-radius: $border-radius5;
        
        .confirm_title {
            margin: 20px 0;
            font-family: $font-type2;
        }

        .confirm_type {
            width: 50px;
            height: 50px;
            border-radius: $border-radius0;
            font-size: $font-size8;

            &.type_0 {
                background-color: rgba($alert-color1, 0.1);
                color: $alert-color1;
                &:after {
                    content: "!"
                }
            }
            &.type_1 {
                background-color: rgba($alert-color4, 0.1);
                color: $alert-color4;
                &:after {
                    content: "!"
                }
            }
            &.type_2 {
                background-color: rgba($alert-color3, 0.1);
                color: $alert-color3;
                &:after {
                    content: "i"
                }
            }
            &.type_3 {
                background-color: rgba($alert-color2, 0.1);
                color: $alert-color2;
                &:after {
                    content: "✓"
                }
            }
        }

        .button_container {
            margin-top: 30px;
        }

        .body {
            padding: 0 20px;

            span {
                font-size: 10px;
                color: red;
            }
        }

        // textarea 스타일 추가
        .textarea_wrapper {
            margin-top: 20px;
            display: flex;
            justify-content: center;

            textarea {
                width: 100%;
                min-height: 100px;
                padding: 8px;
                font-size: 12px;
                border: 1px solid #ccc; // 기본 테두리 색상 유지
                border-radius: 2px; // 둥근 모서리
                outline: none;
                resize: none;
                background-color: #fff;
                color: #333;
                font-family: "Pretendard", sans-serif;
                transition: box-shadow 0.2s ease-in-out; // 부드러운 효과

                &::placeholder {
                    color: #bbb; // 연한 플레이스홀더 색상
                }

                &:focus {
                    border-color: #ccc; // 테두리 색상 유지
                    box-shadow: 0 0 5px rgba(87, 196, 120, 0.3); // 초록빛 그림자 효과 추가
                }
            }
        }

        .option{
            text-align: left;
            padding:0 20px;
            padding-top:20px;
            
            label {
               color:#000;
            }
        }
    }
}

</style>

<style lang="scss">
 .gmx_confirm {
    .body {
        span {
            margin-top:10px;
            display: block;
            font-size:14px;
            color:$state-color4;
        }
    }
}

</style>