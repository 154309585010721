<template>
    <div class="gmx_table_wrap flex flex_column flex_space_1"> 
        <div class="gmx_table">
            <table :style="displayedData.length == 0 ? 'height: 100%' : ''" :class="cl">
                <colgroup>
                  <col v-if="isCheckBox" class="confine">
                  <col v-for="(column, index) in filterColumns" :key="index" :class="column.id == 'projNm' || column.id == 'contNm'  ? 'wide' : ''">
                </colgroup>
                <thead class="gmx_table_header">
                    <tr>
                        <th v-if="isCheckBox" class="gmx_table_header_item gmx_table_chk">
                            <GmxCheckBox v-model="selectAll" @change="toggleSelectAll"></GmxCheckBox>
                        </th>
                        <th class="gmx_table_header_item" v-for="(column, index) in filterColumns" :key="index">
                            <span @click="()=> { if ( isSort )  sortBy(column.id) } ">{{ column.name }}</span>
                            <span v-if="sortColumn === column.id" >
                                {{ sortDirection === 'asc' ? '▲' : '▼' }}
                            </span>
                            
                            <!-- 피할 수 없는 하드 코딩. 이래 col 컴포넌트를 따로 만들어야 하는구나. -->
                            <span v-if="column.type === GmxGlobal.COL_COLTYPE_CD_STATE">
                                <span @mouseover="guideBoxClick('', $event)" @mouseout="()=> isGuideBox = false"><img src="@/assets/img/icn_info.svg"></span>

                                <GuideMsgUnit v-if="isGuideBox" :type="column.id"></GuideMsgUnit>
                                
                            </span>
                            <!-- 그냥.. br이랑 sapn 넣어뒀습니다...! -->
                            <br>
                            
                            <template v-if="column.type === GmxGlobal.COL_COLTYPE_CD_SELECT  && column.search" >
                                <GmxSelectBox v-if="isFilter" :id="column.id" cl="search" :search="true" v-model="column.value" :callBack="handleInput" :items="column.search.option"></GmxSelectBox>
                            </template>

                            <template v-else-if="column.type === GmxGlobal.COL_COLTYPE_CD_STATE  && column.search" >
                                <GmxCheckedSelectBox v-if="isFilter" :id="column.id" cl="search" :search="true" v-model="column.value" :callBack="handleInput" :items="column.search.option"></GmxCheckedSelectBox>
                            </template>

                            <template v-else-if="column.type === GmxGlobal.COL_COLTYPE_CD_STATE_NOGUIDE  && column.search" >
                                <GmxCheckedSelectBox v-if="isFilter" :id="column.id" cl="search" :search="true" v-model="column.value" :callBack="handleInput" :items="column.search.option"></GmxCheckedSelectBox>
                            </template>
                            
                            <!-- <template v-else-if="column.type === GmxGlobal.COL_COLTYPE_CD_DATE">

                                <GmxTextBox  type="date" cl="search" v-model="column.value"  @input="handleInput(column.id, $event.target.value)"></GmxTextBox>
                            </template>
                          -->
                            <template v-else-if="column.type === GmxGlobal.COL_COLTYPE_CD_DATE " >
                                <GmxTextBox v-if="isFilter" :id="column.id" cl="search" type="date" v-model="column.value" :callBack="handleInput" @input="handleInput(column.id, $event.target.value)"></GmxTextBox>
                                <!-- <input v-if="isFilter" class="input" type="date" v-model="column.value" placeholder="검색" onfocus="this.placeholder=''" 
                                    onblur="this.placeholder = '검색'" @input="handleInput(column.id, $event.target.value)"/> -->
                            </template>
                            <template v-else>
                                <input v-if="isFilter" class="input" type="text" v-model="column.value" placeholder="검색" onfocus="this.placeholder=''" 
                                    onblur="this.placeholder = '검색'" @input="handleInput(column.id, $event.target.value)"/>
                            </template>
                        </th>
                    </tr>
                </thead>
                <tbody ref="tableContainer" class="gmx_table_list">
                    <tr v-if="firstLoadRef && displayedData.length == 0">
                        <td :colspan="filterColumns.length">
                            <EmptyUnit></EmptyUnit>
                        </td>
                    </tr>
                    <tr class="gmx_table_item" v-for="(item, index) in displayedData" :key="index" :class="{ 'selected-row': item.selected }" @click="rowClick(item[keyCol], item)">
                        <td v-if="isCheckBox" class="gmx_table_chk">
                            <GmxCheckBox v-model="item.selected" :value="item" @change="handleCheckboxChange(item)" @click.stop="()=>{}"></GmxCheckBox>
                        </td>
                        <td v-for="column in filterColumns" :key="column.id" :class="column.id == 'projNm' || column.id == 'contNm'  ? 'text_left' : ''">
                            <!-- <template v-if="column.key === 'inquiry'" >
                                <button :class="'inquiry-button'" @click="handleButtonClick(item)">  {{ item[column.key] }} </button>
                            </template> -->
                            
                            <template v-if="column.type === GmxGlobal.COL_COLTYPE_CD_STATE" >
                                <span v-if="item[column.id] && isEdit" class="state_type" @click="dropBoxClick(item, $event)" :class="getStateClass(item[column.id])" :title=" item[column.id] ">
                                    {{ item[column.id] }}
                                    <span v-if="item.isDropBox && column.option" class="dropdown">
                                       <!-- 드롭다운 내용 -->
                                        <ul>
                                            <li v-for="( opt, i ) in column.option.filter(opt => opt.value != '')" class="state_type drop_state_type" :class="getStateClass(opt.label)" :key="i" @click="dropBoxItemClick(opt.label, opt.value, item, column, $event)">
                                                <spoan>{{opt.label}}</spoan></li>
                                        </ul>
                                    </span>
                                </span>
                                <span v-if="item[column.id] && !isEdit" class="state_type" :class="getStateClass(item[column.id])" :title=" item[column.id] ">{{ item[column.id] }}</span>

                            </template>

                            <template v-else-if="column.type === GmxGlobal.COL_COLTYPE_CD_STATE_NOGUIDE" >
                                <span v-if="item[column.id] && !isEdit" class="state_type" :class="getStateClass(item[column.id])" :title=" item[column.id] ">{{ item[column.id] }}</span>
                            </template>

                            <template v-else-if="column.type === GmxGlobal.COL_COLTYPE_CD_BTN" >
                                <span v-if="item[column.id] && !isEdit" class="people_count">{{ item[column.id] }}</span>
                                <GmxButton @click="column.callFunction(item[column.cd])"  cl="e" title="설정"></GmxButton> 
                            </template>
                            
                            <template v-else-if="column.type === GmxGlobal.COL_COLTYPE_CD_DATE" >
                                <div :title="DateUtil.convertDateData(item[column.id])" class="item_box">
                                    {{ DateUtil.convertDateData(item[column.id]) }} 
                                </div>
                            </template>
  
                            <template v-else-if="column.type === GmxGlobal.COL_COLTYPE_CD_TIME" >
                                <div :title="DateUtil.convertDateData(item[column.id], true)" class="item_box">
                                    {{ DateUtil.convertDateData(item[column.id], true) }} 
                                </div>
                            </template>

                            <template v-else-if="column.type === GmxGlobal.COL_COLTYPE_CD_PAY" >
                                <div :title="StrUtil.convertPayData(item[column.id])" class="item_box pay_data">
                                    {{ StrUtil.convertPayData(item[column.id]) }} 
                                </div>
                            </template>

                            <template v-else-if="column.type === GmxGlobal.COL_COLTYPE_CD_TAG_ICON" >
                                <div class="item_box">
                                    <img :src="GmxUtil.getClientLevelIcon(item[column.id])">{{  GmxUtil.convertArrayStrign(item[column.id])}} 
                                </div>
                            </template>
                            
                            <template v-else-if="column.type === GmxGlobal.COL_COLTYPE_CD_ICON" >
                                <div class="item_box">
                                    <img :src="item[column.id]">
                                </div>
                            </template>
                            
                            <template v-else-if="column.type === GmxGlobal.COL_COLTYPE_CD_PAY_SALE">
                                <!-- <div :title="item[column.id]" class="item_box gmx_tool_tip" :class="disabledRow != (index+1) > 'dis_row' : ''">  -->
                                <div :title="item[column.id]" class="item_box gmx_tool_tip" :class="showRow(disabledRow, index)"> 
                                    <span v-if="Number(item[column.id]) > 0 ">{{ item['c'+column.id] }}건 /</span> {{ StrUtil.convertAmountKeyword(item[column.id]).toLocaleString() }} 
                                </div>
                            </template>

                            <template v-else-if="column.type === GmxGlobal.COL_COLTYPE_CD_PAY_100">
                                <div :title="item[column.id]" class="item_box gmx_tool_tip"> 
                  
                                    {{ StrUtil.convertAmountKeyword(item[column.id]) .toLocaleString() }} 
                                </div>
                            </template>

                            <template v-else-if="column.type === GmxGlobal.COL_COLTYPE_CD_SERVICE_NAME">
                                <ServiceNameUnit :type="item[column.id]"/>
                            </template>

                            <template v-else-if="column.type === GmxGlobal.COL_COLTYPE_CD_PERCENT">
                                <div :title="item[column.id]" class="item_box gmx_tool_tip"> 
                                     {{ item[column.id] != '-' ? item[column.id]+'%' : '-'}} 
                                </div>
                            </template>

                            <template v-else-if="column.type === GmxGlobal.COL_COLTYPE_CD_PAY_EDIT">
                                <div :title="item[column.id]" class="item_box pay_data"> 
                                    <GmxTextBox type="amount" v-model="item[column.id]"></GmxTextBox>
                                </div>
                            </template>

                            <template v-else-if="column.type === GmxGlobal.COL_TYPE_BEFORE_DATA">
                                <div :title="item[column.id]" class="item_box line_gray"> 
                                    {{ item[column.id] }}
                                </div>
                            </template>

                            <template v-else-if="column.type === GmxGlobal.COL_TYPE_AFTER_DATA">
                                <div :title="item[column.id]" class="item_box red"> 
                                    {{ item[column.id] }}
                                </div>
                            </template>
                            
                            <template v-else>
                                <div :title="item[column.id]" class="item_box" :class="disabledRow > (index+1) ? 'dis_row' : ''">
                                    {{ item[column.id] }}
                                </div>
                            </template>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- <div>
            <button @click="showSelectedRows">선택된 행 확인</button>
        </div> -->
     
        <div v-if="isPaging" class="pagination">
            <GmxButton @click="firstPage" class="btn_first" cl="c"></GmxButton>
            <GmxButton @click="previousPage" :disabled="currentPage === 1" cl="c" class="btn_prev"></GmxButton>
            <ul class="page_list">
                <li v-if="showEllipsisBefore" class="ellipsis">...</li>
                <li v-for="pageNumber in displayedPageNumbers" :key="pageNumber"
                    :class="{ active: currentPage === pageNumber }"
                    @click="goToPage(pageNumber)">
                {{ pageNumber }}
                </li>
                <li v-if="showEllipsisAfter" class="ellipsis">...</li>
            </ul>
            <GmxButton @click="nextPage" :disabled="currentPage === totalPages" cl="c" class="btn_next"></GmxButton>
            <GmxButton @click="lastPage" class="btn_last" cl="c"></GmxButton>
        </div>
    </div>
  </template>
  
  <script setup>
  
    import { ref , computed, defineProps, defineExpose, defineEmits } from 'vue';
    import GmxGlobal from '@/js/global';
    import DateUtil from '@/js/utils/DateUtil';
    import ServiceNameUnit from '@/components/ezone/unit/ServiceNameUnit';
    import StrUtil from '@/js/utils/StrUtil';
    import GmxUtil from '@/js/utils/GmxUtil';
    import GmxButton from '../button/GmxButton.vue';
    import GmxSelectBox from '../select/GmxSelectBox.vue';
    import GmxCheckBox from '../checkBox/GmxCheckBox.vue';
    import GmxCheckedSelectBox from '../select/GmxCheckedSelectBox.vue';
    import EmptyUnit from './unit/EmptyUnit.vue';
    import GuideMsgUnit from './unit/GuideMsgUnit.vue';
    import GmxTextBox from '../text/GmxTextBox.vue';

    const props = defineProps({
        columns: {
            type: Array,
            required: true
        },
        data: {
            type: Array,
            required: true
        },
        pageSize: {
            type: Number,
            default: 20
        },
        keyCol : {
            type : String,
        },
        rowClick : {
            type : Function,
            default : () => {}
        },
        isCheckBox : {
            type : Boolean,
            default : false
        },
        isFilter : {
            type : Boolean,
            default : true
        },
        isPaging : {
            type : Boolean,
            default : true
        },
        isEdit : {
            type : Boolean,
            default : false
        },
        filter : {
            type : Object,
            default : ()=>{ return {t:''}}
        },
        isSort : {
            type : Boolean,
            default : true
        },
        disabledRow : {
            type : String,
            default : ''
        },
        cl : {
            type : String
        },
        loadData : {
            type : Function,
            default : null
        },
        exclude : {
            type: Array,
            default : () => []
        }
    });
    //t는 if문 처리하기 구찮아서.. 추가했음.
    const firstLoadRef = ref(false);
    const filterRef = ref(props.filter);
    const sortColumn = ref('');
    const sortDirection = ref('asc');
    const currentPage = ref(1);
    //const dataRef = ref(props.data);
    const isGuideBox = ref(false);
    const emit = defineEmits(['handleSelectionChange']);

    //체크박스 변경시 상위 컴포넌트에 데이터 전송
    const handleCheckboxChange = (item) => {
        emit('handleSelectionChange', item);
    };
    

    /**
     * 표출되는 컬럼 변경..
     */
    const filterColumns = computed(() => {
        return props.columns.filter(column => !props.exclude.includes(column.id));
    });

    const sortedData = computed(() => {
        //마음 아픈 하드 코딩.
        if (sortColumn.value.indexOf('StateNm') != -1) {
            const customOrder = ['대기','계획', '계약완료', '진행','준공', '완료', '기회', '고려', '적극', '확실','수주', '실패'];
            return filteredData.value.slice().sort((a, b) => {
                const aValue = a[sortColumn.value];
                const bValue = b[sortColumn.value];
                const aIndex = customOrder.indexOf(aValue);
                const bIndex = customOrder.indexOf(bValue);

                // sortDirection.value에 따라 오름차순 또는 내림차순으로 정렬 방향 결정
                if (sortDirection.value === 'asc') {
                    return aIndex - bIndex;
                } else {
                    return bIndex - aIndex;
                }
            });
        }

        if (sortColumn.value) {
            return filteredData.value.slice().sort((a, b) => {
                const aValue = a[sortColumn.value];
                const bValue = b[sortColumn.value];
                if (aValue < bValue) return sortDirection.value === 'asc' ? -1 : 1;
                if (aValue > bValue) return sortDirection.value === 'asc' ? 1 : -1;
                return 0;
            });
        } else {
            return filteredData.value;
        }
    });

    const totalPages = computed(() => Math.ceil(sortedData.value.length / props.pageSize));
  
  
    const displayedData = computed(() => {
        
        const startIndex = (currentPage.value - 1) * props.pageSize;
        const endIndex = startIndex + props.pageSize;
        return sortedData.value.slice(startIndex, endIndex);
    });
  
    const pageCount = computed(() => {
        return Math.ceil(sortedData.value.length / props.pageSize);
    });

  
    //필터 초기화
    const clearFilter = () => {
        for ( const i in props.columns ) {
            delete filterRef.value[props.columns[i].id];
        }
    }

    const getFilter = () => {
        return filterRef.value;
    }

    //정렬
    const sortBy = (columnKey) => {

        if (sortColumn.value === columnKey) {
            sortDirection.value = sortDirection.value === 'asc' ? 'desc' : 'asc';
        } else {
            sortColumn.value = columnKey;
            sortDirection.value = 'asc';
        }
    };
  
    //이전
    const previousPage = () => {
        if (currentPage.value > 1) {
            currentPage.value--;
        }
    };
  
    //다음
    const nextPage = () => {
        if (currentPage.value < pageCount.value) {
            currentPage.value++;
        }
    };
  
    // //해당페이지로
    const goToPage  = (n) => {
         currentPage.value = n;
    }
  
    //가장 처음 페이지로
    const firstPage = () => {
        currentPage.value = 1;
    }
  
    //가장 마지막 페이지로
    const lastPage = () => {
        currentPage.value = totalPages.value;
    }
  
    const displayedPageNumbers = computed(() => {
        
        const maxDisplayedPages = 10;

        if (totalPages.value <= maxDisplayedPages) {
            // 페이지 수가 최대 표시 페이지 수보다 작을 경우 모든 페이지를 표시합니다.
            return Array.from({ length: totalPages.value }, (_, index) => index + 1);
        }

        const halfDisplayed = Math.floor(maxDisplayedPages / 2);
        let startPage = currentPage.value - halfDisplayed;
        let endPage = currentPage.value + halfDisplayed;

        if (startPage <= 0) {
            // 시작 페이지가 1보다 작을 경우 1로 설정합니다.
            startPage = 1;
            endPage = maxDisplayedPages;
        } else if (endPage > totalPages.value) {
            // 끝 페이지가 총 페이지 수보다 클 경우 총 페이지 수로 설정합니다.
            endPage = totalPages.value;
            startPage = totalPages.value - maxDisplayedPages + 1;
        }

        return Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);

    });
    
    // 체크박스 관련 소스 // -- > 시작
    const selectAll = ref(false);
    const selectedRowsRef = ref([]);
  
    const toggleSelectAll = () => {
        selectedRowsRef.value = !selectAll.value ? [] : [...displayedData.value];
    };

    //멀티체크박스에 포함된 row값에 따라서 class 값 리턴
    const showRow = (disabledRow, index) =>{

        if(disabledRow.includes(index + 1) || disabledRow == 0 || index == 5) {
            return ""
        } else{
            return "dis_row"
        }
    }

    //우선 하드.. TODO ahj : 고치자.
    const getStateClass = (state) => {
        //state 값에 공백이 포함되어 있어 공백 제거
        let stataValue = state ? state.replace(/ /g, '') : '';

        //계약 현황인 경우... 이게 맞냐.
        if ( props.keyCol === 'contCd' && stataValue === '진행') {
            stataValue = '적극'
        }
        if(props.keyCol ==='saleCd'){
       
            switch(stataValue) {
                case '기회' :
                case '대기' :
                case '계획' :
                    return 'state1';
                case '고려' :
                case '미흡' :
                    return 'state2';
                case '적극' :
                case '진행' :
                case '계약완료' :
                    return 'state3';
                case '확실' :
                case '준공' :
                case '초과달성' :
                    return 'state4';
                case '수주' :
                case '완료' :
                case '달성' :
                    return 'state5';
                case '수주대기' :
                    return 'state7';
                default :
                    return 'state0';
            }
        }else if(props.keyCol ==='contCd'){
            switch(stataValue) {
                case '계획' :
                    return 'state1';
                case '계약완료' :
                    return 'state3 wd';
                case '진행' :
                case '적극' :
                    return 'state4';
                case '검사검수' :
                    return 'state2 wd';
                case '발행' :
                    return 'state6';
                case '완료' :
                    return 'state5';
                default :
                    return 'state0';
            }
        } else if(props.keyCol === 'projCd'){
            switch(stataValue) {
                case '대기' :
                    return 'state1';
                case '진행' :
                    return 'state2 ';
                case '준공' :
                    return 'state4';
                case '완료' :
                    return 'state5';
                case '승인' :
                    return 'state5';
                case '반려' :
                    return 'state0';
                case '승인대기' :
                    return 'state1';
                default :
                    return 'state0';
            }
        }
        
    };
  
    // 더블 클릭으로 변경 예정
    // const handleButtonClick = (item) => {
    //     // 해당 버튼을 클릭한 행에 대한 처리를 수행합니다.
    //     console.log(item);
    //     alert(item + "개");
    // };
    //필터관련 변수
    
    let filterKeyDownTimer = null;
    let filterKewDownFlag = false;
    //const filterText = ref('');    
    function handleInput(key, value) {
        
        if ( filterKewDownFlag == false ) {
            filterKewDownFlag = true;
        } else {
            clearTimeout(filterKeyDownTimer);
            filterKeyDownTimer = null;
        }

        filterKeyDownTimer = setTimeout(()=>{
            filterKewDownFlag = false;

            if ( value === '' ) {
                delete filterRef.value[key];
            } else {
                filterRef.value[key] = value;
            }
            
            if (props.loadData) props.loadData(filterRef.value);
            
        }, 300);
        
      

        
    }

    //맞는 방법은 아닌것같지만.. 그냥 해.
    setTimeout(()=> {
        if ( !firstLoadRef.value ) firstLoadRef.value = true;
    }, 500);

    //검색!!!
    const filteredData = computed(() => {
      // 필터링 로직을 적용하여 새로운 값을 반환
      // 예시: 'name' 필드에 'value' 값을 포함하는 항목만 필터링
      
      return props.data.filter(item => {
            //이게 맞는지 모르겠다...
            let chk = true;
           // let StateNmList;
            item.selected = false;

            for ( let k in filterRef.value ) {
                if ( k == 't'  ) continue;
                let filterValue = filterRef.value[k].toLowerCase();
                
                if ( k === 'creDat' || k === 'contStd' || k === 'projStd' || k === 'updDat') filterValue = filterValue.replaceAll('-', '');
               

                //문자열을 ','를 기준으로 배열로
                // if ( k.endsWith('StateNm') && filterValue.includes(',')) {
                //     StateNmList = filterValue.split(',');
                // }

                if ( filterValue != '' && StrUtil.isEmpty(item[k]) ) chk = false;
                if ( chk && item[k] !== undefined ) {
                   
                    switch ( typeof item[k] )  {
                        case 'string':
                        case 'object':
                        if ( GmxUtil.getRefType(k) === GmxGlobal.COL_COLTYPE_CD_STATE || GmxUtil.getRefType(k) === GmxGlobal.COL_COLTYPE_CD_STATE_NOGUIDE) {
                                //배열인 경우... 후...
                                const arr = filterValue.split(',');
                                chk =  (item[k] && item[k] != '' ) && arr.some((filter) =>{return filter != '' &&  item[k].toLowerCase() == filter }) ;
                              //  console.log(item[k], filterValue);
                            } else if(GmxUtil.getRefType(k) === GmxGlobal.COL_COLTYPE_CD_SELECT) {
                                console.log('wertwet');
                                chk = (item[k] && item[k] != '' ) && (item[k] === filterValue);
                            } else {
                                chk = (item[k] && item[k] != '' ) && (item[k].toLowerCase().includes(filterValue));
                            }
                          //  chk = (item[k] && item[k] != '' ) && (filterValue.toLowerCase().includes(item[k]) || item[k].toLowerCase().includes(filterValue));
                        break;
                        case 'number':
                         chk = (item[k] && item[k] != '' ) && (item[k]+'').indexOf(filterValue) == 0;
                        break;
                    }
                }

             //   chk = ( k == 'orgMgrNm' && item['orgMgrSeq.orgNm'] == (filterValue.toUpperCase()) );
           
            }
            if ( selectedRowsRef.value ) {
                selectedRowsRef.value.forEach((l)=>{
                    if ( l[props.keyCol] === item[props.keyCol] ) {
                        item.selected = true;
                    }
                });
            }
            return chk;
        });
    });
  
    const getData = () => {
        return props.data.filter(item => {
            //이게 맞는지 모르겠다...
            let chk = true;
            item.selected = false;
            
            for ( let k in filterRef.value ) {
                if ( k == 't'  ) continue;
                let filterValue = filterRef.value[k].toLowerCase();
                
                if ( k === 'creDat' || k === 'contStd' || k === 'projStd' ) filterValue = filterValue.replaceAll('-', '');
             
                if ( filterValue != '' && StrUtil.isEmpty(item[k]) ) chk = false;
                if ( chk && item[k] !== undefined ) {
                  
                    switch ( typeof item[k] )  {
                        case 'string':
                        case 'object':
                        if ( GmxUtil.getRefType(k) === GmxGlobal.COL_COLTYPE_CD_STATE) {
                                //배열인 경우... 후...
                                const arr = filterValue.split(',');
                                chk =  (item[k] && item[k] != '' ) && arr.some((filter) =>{return filter != '' &&  item[k].toLowerCase() == filter }) ;
                              //  console.log(item[k], filterValue);
                            } else {
                                chk = (item[k] && item[k] != '' ) && (item[k].toLowerCase().includes(filterValue));
                            }
                          //  chk = (item[k] && item[k] != '' ) && (filterValue.toLowerCase().includes(item[k]) || item[k].toLowerCase().includes(filterValue));
                        break;
                        case 'number':
                         chk = (item[k] && item[k] != '' ) && (item[k]+'').indexOf(filterValue) == 0;
                        break;
                    }
                }

             //   chk = ( k == 'orgMgrNm' && item['orgMgrSeq.orgNm'] == (filterValue.toUpperCase()) );
           
            }
            if ( selectedRowsRef.value ) {
                selectedRowsRef.value.forEach((l)=>{
                    if ( l[props.keyCol] === item[props.keyCol] ) {
                        item.selected = true;
                    }
                });
            }
            return chk;
        });
    };

    
    const absToggleOptions = (item) => {
        item.isDropBox = false;
    }

    const dropBoxClick = (item, e) => {
        e.stopPropagation();
        
        item.isDropBox = true;
        document.addEventListener("click", () => {
            absToggleOptions(item)
        });
    }

    const dropBoxItemClick = (k, v, item, column, e) => {
        e.stopPropagation();
        column.search.callBack(k, v, item);

        item.isDropBox = false;
    }

    
    ///
    const abcGuideToggleOptions = () => {
        isGuideBox.value = false;
    }

    const guideBoxClick = (item, e) => {
        e.stopPropagation();
        
        isGuideBox.value = true;
        document.addEventListener("click", () => {
            abcGuideToggleOptions(item)
        });
    }

    defineExpose({
        getData,
        firstPage,
        clearFilter,
        getFilter
    });

  </script>
  
  <style scoped lang="scss">
  
    .gmx_table_wrap {
        width: 100%;
        .gmx_table {
            position: relative;
            margin: 16px 0;
            border-bottom: 1px solid $table-border;
            overflow: auto;
            table {
                table-layout: fixed;
                width: 100%;
                font-size: $font-size2;
                col {
                  width: auto;
                  &.confine {
                      width: 50px;
                  }
                  &.wide {
                      width: 25%;
                  }
                }
            }
            .gmx_table_header {
                .gmx_table_header_item {
                    position: sticky;
                    top: 0;
                    z-index: 2;
                    padding: 12px 5px;
                    background-color: $table-head;
                    text-align: center;
                    font-family: $font-type2;
                    &.gmx_table_chk {
                        text-align: center;
                    }
                }
                .input {
                    text-align: left;
                    border: 1px solid $gmx-box-br-color;
                    background: $gmx-theme-color;
                    padding:3px;
                    margin-top:5px;
                    border-radius: 5px;
                    text-indent: 5px;
                    width:100% !important;
                    &::placeholder {color:#c4c4c4; font-size : 14px;}
                }
                
                img {
                    cursor:pointer;
                    margin:0 5px;
                    margin-top:2px;
                    vertical-align: text-top;
                }
            }
            .gmx_table_list {
                .gmx_table_item {
                    position: relative;
                    box-sizing: border-box;
                    border-bottom: 1px solid $table-border;
                    cursor: pointer;
                    >td {
                        position: relative;
                        padding: 18px 5px;
                        text-align: center;

                        &.gmx_table_chk {
                            text-align: center;
                        }
                        
                        &.text_left {
                            text-align: center;
                        }
                        .item_box {
                            width: 95%;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                        }
                        .pay_data {
                            text-align: right;
                        }
                    }
                    &.selected-row{
                        background-color: $table-highlight;
                    }
                    &:hover {
                        &:not(.selected-row) {
                            background-color: $table-highlight2;
                        }
                    }
                    &:last-child {
                        border-bottom: 0;
                    }
                }
            }
        }
  
        .pagination {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin-top: 25px;
            button {
                width: 16px;
                height: 16px;
            }
            .btn_prev,
            .btn_next {
                background-image: url(@/assets/img/icn_right_arrow.png);
            }
  
            .btn_first,
            .btn_last {
                background-image: url(@/assets/img/icn_right_dbarrow.png);
            }
  
            .btn_first,
            .btn_prev {
                transform: rotate(180deg);
            }
            .page_list {
                display: flex;
                align-items: center;
                >li {
                    margin: 0 10px;
                    color: $gmx-font-sub-color2;
                    font-size: $font-size2;
                    cursor: pointer;
                    &.active {
                        color: $gmx-main-color;
                    }
                }
            }
        }
    }
  
    .selected-row {
        background-color: yellow;
    }
  
    .inquiry-button{
        border: 1px solid #5090FF;
        color : rgb(0,133,255);
        border-radius: $border-radius2;
        padding: 3px 16px;
    }
  
    .state_type {
        padding: 6px 16px;
        color: $gmx-font-white-color;
        border-radius: $border-radius1;

        &.drop_state_type {
            padding: 4px 16px;
        }

        &.state1 {
        background-color: $state-color1;
            &.wd {
                padding: 6px 8px;
            }
        }
        
        &.state2 {
            background-color: $state-color2;
            &.wd {
                padding: 6px 8px;
            }
        }
  
        &.state3 {
            background-color: $state-color3;

            &.wd {
                padding: 6px 8px;
            }
        }
  
        &.state4 {
            background-color: $state-color4;
            &.wd {
                padding: 6px 8px;
            }
        }
  
  
        &.state5 {
            background-color: $state-color5;
            &.wd {
                padding: 6px 8px;
            }
        }

        &.state6 {
            background-color: $state-color6;
            &.wd {
                padding: 6px 8px;
            }
        }

        &.state7 {
            background-color: $state-color7;
            &.wd {
                padding: 6px 8px;
            }
        }        
  
        &.state0 {
            background-color: $state-color0;
            &.wd {
                padding: 6px 8px;
            }
        }
    }

    .state_type {
        position: relative;

    }
    .dropdown {

        text-align: center;
        width:100%;
        position: absolute;
        z-index:99;
        left: -15px;
        top: calc(100% + 5px);
        ;

        > ul {
            
            padding:14px;
            margin:0;
            background-color: $gmx-theme-color;
            border-radius: 3px;
            
            width: 154%;
            text-align: left;
            border: 1px solid #bbb;
            box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.3);

            > li {
               margin-top:15px;
                &:hover{
                    font-weight: bold;
                }
            }

            > li:first-child {
                margin-top:0;
            }


        }
    }



    .dis_row {
        color:$gmx-box-sub-br-color
    }
    img {
        display: inline-block;
        vertical-align: bottom;
        margin-right: 3px;
    }

    .people_count{
        margin-right: 11px;
    }

    .red { 
        color : $state-color0;
    }

    .line_gray {
        color : $state-color1;
        text-decoration: line-through;
    }

  </style>
  
  <style>
  input:placeholder-shown {
        color: #888; /* 회색으로 설정 */
    }
</style>